/* eslint-disable no-irregular-whitespace */
import React from 'react'
import Pagina from '../components/Pagina'
import { graphql } from 'gatsby'

const LeiLGPD = ({ data }) => {
  return (
    <>
      <Pagina pagina={data.pagina} />
      <div className="container mt-5 mb-5">
        <h2 className="mb-4"><b>POLÍTICA GERAL DE PROTEÇÃO DE DADOS</b></h2>
        {/* <h2>PROGRAMA DE GOVERNANÇA DE PRIVACIDADE E PROTEÇÃO DE DADOS</h2> */}
        <p>
          A Lwart Soluções Ambientais possui como compromisso garantir a proteção dos dados pessoais e a segurança das informações coletadas dos titulares, com estrita observância a Lei Geral de Proteção de Dados (LGPD), nº 13.709 de 2018, se atentando sempre a boa-fé e demais princípios nela definidos para o tratamento de dados, tais como:
        </p>
        <ol style={{ listStyleType: 'lower-alpha' }}>
          <li>
            <p>finalidade: realização do tratamento para propósitos legítimos, específicos, explícitos e informados ao titular, sem possibilidade de tratamento posterior de forma incompatível com essas finalidades;</p>
          </li>
          <li>
            <p>adequação: compatibilidade do tratamento com as finalidades informadas ao titular, de acordo com o contexto do tratamento;</p>
          </li>
          <li>
            <p>necessidade: limitação do tratamento ao mínimo necessário para a realização das finalidades;</p>
          </li>
          <li>
            <p>livre acesso: garantia, aos titulares, de consulta facilitada e gratuita sobre a forma e a duração do tratamento, bem como sobre a integralidade de seus dados pessoais;</p>
          </li>
          <li>
            <p>qualidade dos dados: garantia, aos titulares, de exatidão, clareza, relevância e atualização dos dados, de acordo com a necessidade e para o cumprimento da finalidade de seu tratamento;</p>
          </li>
          <li>
            <p>transparência: garantia, aos titulares, de informações claras, precisas e facilmente acessíveis, observados os segredos comerciais e industriais;</p>
          </li>
          <li>
            <p>segurança: utilização de medidas técnicas e administrativas aptas a proteger os dados pessoais de acessos não autorizados e de situações acidentais ou ilícitas;</p>
          </li>
          <li>
            <p>prevenção: adoção de medidas para prevenir a ocorrência de danos em virtude do tratamento de dados pessoais;</p>
          </li>
          <li>
            <p>não discriminação: impossibilidade de realização do tratamento para fins discriminatórios ilícitos ou abusivos;</p>
          </li>
        </ol>
        <ol className="pl-0 mt-5" style={{ listStyleType: 'none' }}>
          <li>
            <h3>1. Definições:</h3>
            <ol style={{ listStyleType: 'none' }}>
              <li>
                <p><b>1.1 dado pessoal: </b>informação relacionada a pessoa natural identificada ou identificável;</p>
              </li>
              <li>
                <p><b>1.2 dado pessoal sensível: </b>dado pessoal sobre origem racial ou étnica, convicção religiosa, opinião política, filiação a sindicato ou a organização de caráter religioso, filosófico ou político, dado referente à saúde ou à vida sexual, dado genético ou biométrico, quando vinculado a uma pessoa natural;</p>
              </li>
              <li>
                <p><b>1.3 banco de dados: </b>conjunto estruturado de dados pessoais, estabelecido em um ou em vários locais, em suporte eletrônico ou físico;</p>
              </li>
              <li>
                <p><b>1.4 titular: </b>pessoa natural a quem se referem os dados pessoais que são objeto de tratamento;</p>
              </li>
              <li>
                <p><b>1.5 controlador: </b>pessoa natural ou jurídica, de direito público ou privado, a quem competem as decisões referentes ao tratamento de dados pessoais;</p>
              </li>
              <li>
                <p><b>1.6 tratamento: </b>toda operação realizada com dados pessoais, como as que se referem a coleta, produção, recepção, classificação, utilização, acesso, reprodução, transmissão, distribuição, processamento, arquivamento, armazenamento, eliminação, avaliação ou controle da informação, modificação, comunicação, transferência, difusão ou extração;</p>
              </li>
              <li>
                <p><b>1.7 anonimização: </b>utilização de meios técnicos razoáveis e disponíveis no momento do tratamento, por meio dos quais um dado perde a possibilidade de associação, direta ou indireta, a um indivíduo;</p>
              </li>
              <li>
                <p><b>1.8 consentimento: </b>manifestação livre, informada e inequívoca pela qual o titular concorda com o tratamento de seus dados pessoais para uma finalidade determinada;</p>
              </li>
              <li>
                <p><b>1.9 eliminação: </b>exclusão de dado ou de conjunto de dados armazenados em banco de dados, independentemente do procedimento empregado;</p>
              </li>
            </ol>
          </li>
          <li className="mt-5">
            <h3>2. Tratamento de dados:</h3>
            <p>A Lwart somente realiza o tratamento de dados nas seguintes hipóteses, respeitado o tempo de armazenamento inerente a cada dado coletado e sua respectiva finalidade: </p>
            <ol style={{ listStyleType: 'lower-alpha' }}>
              <li>
                <p>mediante o fornecimento de consentimento pelo titular;</p>
              </li>
              <li>
                <p>para o cumprimento de obrigação legal ou regulatória pelo controlador;</p>
              </li>
              <li>
                <p>para a realização de estudos por órgão de pesquisa, garantida, sempre que possível, a anonimização dos dados pessoais;</p>
              </li>
              <li>
                <p>quando necessário para a execução de contrato ou de procedimentos preliminares relacionados a contrato do qual seja parte o titular;</p>
              </li>
              <li>
                <p>para o exercício regular de direitos;</p>
              </li>
              <li>
                <p>para a proteção da vida ou da incolumidade física do titular ou de terceiro;</p>
              </li>
              <li>
                <p>para a tutela da saúde;</p>
              </li>
              <li>
                <p>quando necessário para atender aos interesses legítimos do controlador ou de terceiro;<br /> ou</p>
              </li>
              <li>
                <p>para a proteção do crédito.</p>
              </li>
            </ol>
            <p>Para cada uma das modalidades de tratamento de dados poderão ser solicitadas diferentes informações, conforme a finalidade a que a coleta se destina.</p>
            <p>No caso de necessidade de consentimento do titular, estes serão previamente informados sobre a finalidade e uso de seus dados, bem como o prazo de armazenamento, podendo ou não consentir com o tratamento.</p>
          </li>
          <li className="mt-5">
            <h3>3. Direitos do titular: </h3>
            <p>Os titulares dos dados poderão solicitar à Lwart através de simples requerimento:</p>
            <ol style={{ listStyleType: 'lower-alpha' }}>
              <li>
                <p>confirmação da existência de tratamento;</p>
              </li>
              <li>
                <p>acesso aos dados;</p>
              </li>
              <li>
                <p>correção de dados incompletos, inexatos ou desatualizados;</p>
              </li>
              <li>
                <p>anonimização, bloqueio ou eliminação de dados desnecessários, excessivos ou tratados em desconformidade com o disposto na Lei;</p>
              </li>
              <li>
                <p>portabilidade dos dados a outro fornecedor de serviço ou produto, observados os segredos comerciais e industriais; </p>
              </li>
              <li>
                <p>eliminação dos dados pessoais tratados com o consentimento do titular, exceto nos casos em que há autorização de sua conservação;</p>
              </li>
              <li>
                <p>informação das entidades públicas e privadas com as quais realizou uso compartilhado de dados;</p>
              </li>
              <li>
                <p>informação sobre a possibilidade de não fornecer consentimento e sobre as consequências da negativa;</p>
              </li>
              <li>
                <p>revogação do consentimento.</p>
              </li>
            </ol>
          </li>
          <li className="mt-5">
            <h3>4. Disposições finais</h3>
            <p>A Lwart não realiza a divulgação de nenhum dado pessoal, exceto nos casos previamente acordados entre as partes, com o devido consentimento expresso do titular, ou nos casos autorizadores pela lei. </p>
            <p>A Lwart armazena os dados de forma segura, íntegra e assegura aos titulares camadas de proteção com soluções tecnológicas.</p>
            <p>É disponibilizado aos titulares um canal de atendimento para que possam fazer suas solicitações ou sanar suas dúvidas, através do site <a href="https://www.lwart.com.br/">www.lwart.com.br</a> ou e-mail para <a href="mailto:lgpd@lwart.com.br">lgpd@lwart.com.br</a> , resguardado o sigilo e confidencialidade. </p>
            <p>O site da Lwart eventualmente poderá fazer redirecionamento para outros sites, cujos conteúdos e formas de tratamento de dados não são de sua responsabilidade, nem estão sob seu controle ou englobam a presente política.</p>
          </li>
        </ol>
        <h4 className="mt-5 text-center">LWART SOLUÇÕES AMBIENTAIS LTDA.</h4>
      </div>
    </>
  )
}

export default LeiLGPD

export const query = graphql`
  query {
    pagina:contentfulPagina(path: { eq: "/lei-lgpd/" }) {
      path
      metaTitle
      metaDescription
      pageTitle
      tags
      ogImage {
        localFile {
          publicURL
        }
      }
    }
  }
`
